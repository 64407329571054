import { Component, OnInit } from "@angular/core";
import { UserService } from "src/services/api";
import { User } from "src/app/models";
import { MsalBroadcastService, MsalService } from "@azure/msal-angular";
import {
  EventMessage,
  EventType,
  InteractionStatus,
} from "@azure/msal-browser";
import { filter } from "rxjs/operators";
import { Observable } from "rxjs";
import { LANGUAGES } from "src/app/enums/languages.enum";
import { TranslateService } from "@ngx-translate/core";
import { Router } from '@angular/router';

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit {
  loggedInUser: Observable<User>;
  languagesList: Array<string>;
  languageSelected: string;

  constructor(
    private userService: UserService,
    private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private translateService: TranslateService,
    private router: Router
  ) {
    this.languagesList = Object.keys(LANGUAGES);
    this.languageSelected = this.languagesList[0];
  }

  ngOnInit(): void {
    this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS)
      )
      .subscribe((result: EventMessage) => {
        console.log(result);
      });

    this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None)
      )
      .subscribe(() => {
        if (this.isLoggedIn()) {
          this.loggedInUser = this.userService.getCurrentUser();
        }
      });
  }

  isLoggedIn(): boolean {
    return this.authService.instance.getAllAccounts().length > 0;
  }

  onLanguageChange() {
    this.translateService.use(LANGUAGES[this.languageSelected]);
  }

  navigate() {
    this.router.navigate(['/inspection-template-list']); 
  }

}
