import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { MaterialModule } from "./modules/material.module";
import { TranslateModule } from "@ngx-translate/core";
import { Paging } from "./models/paging.model";
@NgModule({
  imports: [CommonModule, MaterialModule],
  declarations: [],
  exports: [CommonModule, FormsModule, MaterialModule, TranslateModule],
  providers: [
    // nothing here
  ],
})
export class SharedModule {}
