import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Lookup } from "src/app/models";
import { environment } from "../../../../environments/environment";

@Injectable({
  providedIn: "any",
})
export class InspectionService {
  constructor(private http: HttpClient) {}

  public getInspectionList(
    organizationId: number,
    lstAreaId: number[],
    lstMobileStatus: string[],
    lstWorkOrderStatus: string[]
  ): Observable<number[]> {
    const body = {
      organizationId: organizationId,
      area: lstAreaId,
      mobileStatus: lstMobileStatus,
      workOrderStatus: lstWorkOrderStatus,
    };
    return this.http.post<number[]>(
      `${environment.apiEndpoint}/inspection/getInspectionList`,
      body
    );
  }

  public getWorkOrderStatusList(organizationId: number): Observable<Lookup[]> {
    return this.http.get<Lookup[]>(
      `${environment.apiEndpoint}/inspection/getWorkorderStatusList/${organizationId}`
    );
  }

  public getInspectionsFiltered(currentPage: number, pageSize: number) {
    var params = new HttpParams()
      .set("currentPage", `${currentPage}`)
      .set("pageSize", `${pageSize}`);
    return this.http.get(
      `${environment.apiEndpoint}/inspection/getInspectionsFiltered`,
      { params: params }
    );
  }
}
