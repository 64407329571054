<header>
  <!-- SEARCH -->
  <div id="search" role="search">
    <form id="search-form" action="https://www.office.com/search?auth=2" target="_blank" method="get">
      <em class="icon-search" aria-hidden="true"></em>
      <input id="search-text" autocomplete="off" type="text" name="q" spellcheck="false"
        placeholder="what can we help you find?" aria-label="what can we help you find?" />
      <button id="search-clear" type="reset" title="clear" aria-label="clear search text">
        clear
      </button>
      <button id="search-button" type="submit" title="search" aria-label="submit search">
        <em class="icon-arrow-right"></em>
      </button>
    </form>
  </div>

  <!-- TOP MENU TOGGLE -->
  <div mat-icon-button [matMenuTriggerFor]="menu" id="toggle-menu" class="topmenu" role="button"
    aria-label="Toggle top menu navigation" aria-pressed="false">
    <span class="icon-menu-hamburger"><em>{{ "HEADER.menu" | translate }}</em></span>
  </div>
  <mat-menu #menu="matMenu">
    <button mat-menu-item>
      <span>Business Units</span>
    </button>
    <button mat-menu-item>
      <span>Organizations</span>
    </button>
    <button mat-menu-item>
      <span>Bugs and enhacements</span>
    </button>
    <button mat-menu-item (click)="navigate()">
      <span>Inspection templates</span>
    </button>
    <button mat-menu-item>
      <span>Manage versions</span>
    </button>
  </mat-menu>

  <!-- TOP MENU -->
  <div class="headernav" id="top-menu"></div>

  <!-- HALLMARK & SITE TITLE -->
  <div id="sitename">
    <a id="hallmark" href="/"></a>
    <h3>
      <a href="/">
        <h3 class="text-light-blue">inspections web portal</h3>
      </a>
    </h3>
  </div>

  <div class="container">
    <div class="row">
      <div class="col-6">
        <div *ngIf="isLoggedIn()">
          <div *ngIf="loggedInUser | async as user; else loading_user">
            <h5 class="text-left">
              {{ "HEADER.welcome" | translate | uppercase }} {{ user.FirstName | uppercase }} {{ user.LastName | uppercase }}
              
            </h5>
          </div>
        </div>
      </div>
      <div class="col-6">
        <div class="row">
          <div class="col-12 text-right">
            <mat-form-field appearance="fill">
              <mat-label>{{ "HEADER.language" | translate }}</mat-label>
              <mat-select [(value)]="languageSelected" (selectionChange)="onLanguageChange()">
                <mat-option *ngFor="let option of languagesList" [value]="option">
                  {{ option }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>
  </div>

  <nav id="site-specific-nav">
    <ul>
      <li><a routerLink="/inspection">{{ "MENU.inspections" | translate }}</a></li>
      <li><a routerLink="/inspection">{{ "MENU.equipment" | translate }}</a></li>
      <li><a routerLink="/inspection">{{ "MENU.actions" | translate }}</a></li>
      <li><a routerLink="/inspection">{{ "MENU.dashboard" | translate }}</a></li>
      <li>
        <a href="">{{ "MENU.administration" | translate }}</a>
        <ul>
          <li><a routerLink="/user">{{ "MENU.users" | translate }}</a></li>
        </ul>
      </li>
      <li>
        <a href="">Demo</a>
        <ul>
          <li><a routerLink="/demo/documentation">Documentation</a></li>
          <li><a routerLink="/loaderDemo">Loader</a></li>
          <li><a routerLink="/demo/controls">Controls</a></li>
        </ul>
      </li>
      <li></li>
    </ul>
  </nav>
</header>

<ng-template #loading_user>
  <div class="row">
    <div class="col-12">
      <h5 class="text-left">i'm trying to get your name...</h5>
    </div>
  </div>
</ng-template>