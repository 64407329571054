import { NgModule, Optional, SkipSelf } from "@angular/core";
import { CommonModule } from "@angular/common";
import { HeaderComponent } from "./components/header/header.component";
import { RouterModule } from "@angular/router";
import { SharedModule } from "src/app/shared/shared.module";
import { FooterComponent } from "./components/footer/footer.component";
import { LoadingIndicatorModule } from "./components/loading-indicator/loading-indicator.module"
import { PromptYesNoModule } from './components/promptYesNo/promptYesNo.module'
import { PromptInfoModule } from './components/promptInfo/promptInfo.module'

@NgModule({
  imports: [CommonModule, RouterModule, SharedModule],
  declarations: [HeaderComponent, FooterComponent],
  exports: [HeaderComponent, FooterComponent, LoadingIndicatorModule, PromptYesNoModule, PromptInfoModule],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule?: CoreModule) {
    if (parentModule) {
      throw new Error(
        "CoreModule is already loaded. Import it in the AppModule only"
      );
    }
  }
}
