import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { LogoutComponent } from './pages/auth/logout/logout.component';
import { LoaderDemoComponent } from './pages/loaderDemo/loaderDemo.component';

const routes: Routes = [
  // Home Page
  {
    path: '',
    redirectTo: 'inspection',
    pathMatch: 'full'
  },
  {
    path: 'inspection',
    loadChildren: () => import('./pages/inspection/inspection.module').then(module => module.InspectionModule),
    canActivate: [MsalGuard]
  },
  {
    path: 'user',
    loadChildren: () => import('./pages/user/user.module').then(module => module.UserModule),
    canActivate: [MsalGuard]
  },
  {
    path: 'logout',
    component: LogoutComponent
  },
  {
    path: 'loaderDemo',
    component: LoaderDemoComponent
  },
  {
    path: 'demo', // one route by entity, the additional routes are added in the routing.module file inside pages folder
    loadChildren: () => import('./pages/demo/demo.module').then(module => module.DemoModule),
    canActivate: [MsalGuard]
  },
  // {
  //   path: '**',
  //   component: HomeComponent,
  //   canActivate: [MsalGuard]
  // },


];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
