export const environment = {
  production: true,
  apiEndpoint: "https://mit-api.azure.chevron.com/api/",

  msalConfig: {
    clientId: '66752d6e-814b-4be9-b9ab-001e62c3c852',
    authority: 'https://login.microsoftonline.com/chevron.onmicrosoft.com',
    redirectUri: 'https://mit.azure.chevron.com',
    postLogoutRedirectUri: 'http://localhost:4200/logout/'
  }
};
